<template>
  <InstructionLayout
    :id="instructions.id"
    :title="instructions.title"
    :steps="instructions.steps"
    :steps-by-type="instructions.stepsByType"
    :steps-by-type-options="instructions.stepsByTypeOptions"
  >
    <template #preview="{ src }">
      <img :src="src" alt="outlook" />
    </template>
  </InstructionLayout>
</template>

<script setup lang="ts">
import { instructionsData } from '../../../instructions-service';
import InstructionLayout from '../instruction-layout.vue';

const instructions = instructionsData.outlookWeb;
</script>

<style scoped>
img {
  max-height: 500px;
}
</style>
