<script lang="ts" setup>
import * as UC from '@uploadcare/file-uploader';
import '@uploadcare/file-uploader/web/uc-file-uploader-regular.min.css';
import { onMounted, onUnmounted, ref, useTemplateRef } from 'vue';

import { toast, ToastStatus } from '@mfl/common-components';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';

import strings from '../employee-portal-signatures.strings';

UC.defineComponents(UC);

const IMAGE_BASE_URL = 'https://d36urhup7zbd7q.cloudfront.net';

const uploadcarePubkey = import.meta.env.VITE_UPLOADCARE_PUBKEY;

defineProps<{
  name: string;
  photo?: string;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  done: [event: { imageUrl: string }];
}>();

const uploadcareConfig = useTemplateRef<UC.ConfigType>('uploadcare-config');
const uploaderCtx =
  useTemplateRef<InstanceType<UC.UploadCtxProvider>>('uploader-ctx');

const fileMimeType = ref<string>();

function onFileAdded(event: CustomEvent<UC.EventPayload['file-added']>) {
  fileMimeType.value = event.detail.mimeType;
}

async function onDoneClick(event: CustomEvent<UC.EventPayload['done-click']>) {
  const entry = event.detail.successEntries[0];

  try {
    const res = await employeePortalGateway.uploadProfilePicture({
      uuid: entry.uuid,
      cdnUrl: entry.cdnUrl,
      imageFormat: entry.fileInfo.imageInfo?.format,
    });

    if (!res.objectKey) {
      throw new Error('Got no objectKey from upload');
    }

    emit('done', { imageUrl: new URL(res.objectKey, IMAGE_BASE_URL).href });
  } catch (error) {
    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.profilePhotoUploadUnexpectedError,
      status: ToastStatus.Error,
    });

    throw error;
  }
}

onMounted(() => {
  if (!uploadcareConfig.value || !uploaderCtx.value) return;

  uploadcareConfig.value.iconHrefResolver = ((iconName) => {
    if (iconName === 'default') {
      return '#uc-custom-icon-default';
    }
  }) as UC.ConfigType['iconHrefResolver'];

  uploadcareConfig.value.localeDefinitionOverride = {
    en: {
      'drop-files-here': 'Drop file here',
    },
  };

  uploaderCtx.value.addEventListener('file-added', onFileAdded);
  uploaderCtx.value.addEventListener('done-click', onDoneClick);
});

onUnmounted(() => {
  if (!uploaderCtx.value) return;

  uploaderCtx.value.removeEventListener('file-added', onFileAdded);
  uploaderCtx.value.removeEventListener('done-click', onDoneClick);
});
</script>

<template>
  <div
    class="relative flex h-[100px] w-[100px] items-center justify-center rounded-full bg-gray-200 overflow-hidden group"
  >
    <img
      v-if="photo"
      :src="photo"
      alt="Profile Photo"
      class="h-full w-full object-cover"
    />
    <span v-else class="text-[50px] font-bold text-gray-400">
      {{ name.charAt(0).toUpperCase() }}
    </span>

    <div
      v-if="readonly"
      class="absolute inset-0 flex items-center justify-center bg-black text-white rounded-full opacity-0 transition-opacity group-hover:opacity-60"
    >
      <span class="fa-solid fa-lock text-[32px]"></span>
    </div>

    <button
      v-else
      class="absolute inset-0 flex items-center justify-center bg-black text-white rounded-full opacity-0 transition-opacity group-hover:cursor-pointer group-hover:opacity-60"
      @click="uploaderCtx?.getAPI().initFlow()"
    >
      <span class="fa-light fa-camera text-[32px]"></span>
    </button>

    <uc-config
      ref="uploadcare-config"
      ctx-name="my-uploader"
      :pubkey="uploadcarePubkey"
      multiple="false"
      img-only="true"
      camera-capture="user"
      source-list="local, url, camera, dropbox, gdrive"
      max-local-file-size-bytes="10485760"
      :use-cloud-image-editor="fileMimeType !== 'image/gif'"
      :crop-preset="fileMimeType === 'image/gif' ? '' : '1:1'"
    />

    <uc-upload-ctx-provider ref="uploader-ctx" ctx-name="my-uploader" />

    <uc-file-uploader-regular
      ctx-name="my-uploader"
      headless="true"
      class="uc-light"
    />

    <svg xmlns="http://www.w3.org/2000/svg" class="hidden">
      <symbol id="uc-custom-icon-default" viewBox="0 0 26 26" fill="none">
        <rect width="26" height="26" rx="13" class="fill-primary" />
        <path
          fill="white"
          d="M13.75 7.25C13.75 6.83437 13.4156 6.5 13 6.5C12.5844 6.5 12.25 6.83437 12.25 7.25V12.25H7.25C6.83437 12.25 6.5 12.5844 6.5 13C6.5 13.4156 6.83437 13.75 7.25 13.75H12.25V18.75C12.25 19.1656 12.5844 19.5 13 19.5C13.4156 19.5 13.75 19.1656 13.75 18.75V13.75H18.75C19.1656 13.75 19.5 13.4156 19.5 13C19.5 12.5844 19.1656 12.25 18.75 12.25H13.75V7.25Z"
        />
      </symbol>
    </svg>
  </div>
</template>

<style scoped lang="scss">
uc-file-uploader-regular {
  --uc-font-family: Mulish, sans-serif;
  --uc-button-size: 38px;
  --uc-radius: calc(8px / 1.75);
  --uc-dialog-shadow-light: var(--box-shadow);
  --uc-foreground-light: var(--color-gray-500);
  --uc-primary-rgb-light: var(--color-primary);
  --uc-secondary-foreground-light: var(--color-gray-500);

  :where(.uc-cancel-btn, .uc-done-btn, .uc-url-upload-btn) {
    padding-right: 14px;
    padding-left: 14px;
  }

  :deep(uc-drop-area) {
    border-radius: 6px;
    border: 2px dashed rgb(var(--color-gray-200));
    background-color: rgb(var(--color-primary-50));

    uc-icon[name='default'] > svg {
      width: 32px;
      height: 32px;
    }

    .uc-content-wrapper .uc-text {
      font-weight: 700;
    }
  }

  :deep(uc-start-from .uc-secondary-btn) {
    border-radius: 30px;
    padding: 10px 22px;
    border: 1px solid rgb(var(--color-gray-200));
    background-color: white;
    font-weight: 600;
  }
}
</style>
