<script setup lang="ts">
import { computed } from 'vue';
import SignatureCard from './signature-card.vue';
import { type Signature } from '@msl/employee-portal-gateway-sdk';
import strings from '../../employee-portal-signatures.strings';

const props = defineProps<{
  signatures: Signature[];
  instructions: string[];
}>();

const sortedSignatures = computed(() => {
  return props.signatures.toSorted((a, b) => {
    if (a.isPrimary) return -1;
    if (b.isPrimary) return 1;
    if (a.isReply) return -1;
    if (b.isReply) return 1;
    if (a.isForward) return -1;
    if (b.isForward) return 1;
    return 0;
  });
});
</script>

<template>
  <div
    v-if="signatures.length > 0"
    class="flex flex-wrap gap-[14px] justify-start mb-6"
  >
    <SignatureCard
      v-for="signature in sortedSignatures"
      :key="signature.title"
      :signature="signature"
      :instructions="instructions"
    />
  </div>

  <div v-else class="mt-[160px]">
    <img
      class="mb-[60px] max-w-[300px] mx-auto"
      src="/no-signatures.png"
      alt="No signatures"
    />

    <p class="text-3xl font-semibold text-center">
      {{ strings.signaturesViewNoSignaturesText }}
    </p>
  </div>
</template>
