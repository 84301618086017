<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SignatureList from './signature-list.vue';
import { WsButton, vLoadingL } from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';
import { useRouter } from 'vue-router';
import strings from '../../employee-portal-signatures.strings';
import { openEmployeeSignatureRulesDialog } from './employee-signature-rules-dialog';
import {
  type Signature,
  employeePortalGateway,
} from '@msl/employee-portal-gateway-sdk';

const signatures = ref<Signature[]>([]);
const instructions = ref<string[]>([]);
const employeeSignatureRulesEnabled = ref<boolean>();
const loading = ref(false);

onMounted(async () => {
  loading.value = true;

  try {
    await Promise.all([
      employeePortalGateway.getSignatures({}).then((data) => {
        signatures.value = data.signatures || [];
        employeeSignatureRulesEnabled.value =
          data.employeeSignatureRulesEnabled ?? false;
      }),
      employeePortalGateway.getDomainSettings({}).then((data) => {
        instructions.value = data.instructions || [];
      }),
    ]);
  } finally {
    loading.value = false;
  }
});

async function openSettings() {
  const output = await openEmployeeSignatureRulesDialog({
    signatures: signatures.value,
  });
  if (!output) return;
  signatures.value = signatures.value.map((signature) => ({
    ...signature,
    isPrimary: signature === output.primarySignature,
    isReply: signature === output.replySignature,
    isForward: signature === output.forwardSignature,
  }));
}

const router = useRouter();
</script>

<template>
  <div v-loading-l="loading" style="min-height: calc(100vh - 55px)">
    <div v-if="!loading" class="px-6">
      <div class="flex justify-start items-center py-6">
        <div class="flex-1 text-2xl font-bold">
          {{ strings.signaturesViewTitle }}
        </div>

        <WsButton
          v-if="employeeSignatureRulesEnabled && signatures.length > 0"
          :label="strings.employeeSignatureRulesSettingsBtnText"
          variant="outlined"
          size="sm"
          aid="OPEN_RULE_SETTINGS"
          class="me-[10px]"
          @click="openSettings()"
        />

        <WsButton
          :label="strings.signaturesViewEditDetailsButton"
          size="sm"
          aid="EDIT_DETAILS_BUTTON"
          @click="navigateTo(router.resolve({ name: 'profile' }).href)"
        />
      </div>

      <SignatureList :signatures="signatures" :instructions="instructions" />
    </div>
  </div>
</template>
