import { createWebHistory, createRouter } from 'vue-router';
import { installInstructionsRoutes } from '../views/instructions/navigation/instructions-nav';

import Signatures from '../views/signatures/signatures-view.vue';
import SignatureEdit from '../views/signatures/signature-edit.vue';
import Profile from '../views/profile-view.vue';

/**
 * create a vue router with routes
 * used for both runtime and tests
 * @returns a vue-router
 */
export function createEmployeePortalRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        name: 'root',
        path: '/',
        redirect: { name: 'signatures' },
      },
      {
        name: 'profile',
        path: '/profile',
        component: Profile,
      },
      {
        name: 'signature',
        path: '/signature/:signatureId/edit',
        component: SignatureEdit,
      },
      {
        name: 'signatures',
        path: '/signatures',
        component: Signatures,
      },
    ],
  });

  installInstructionsRoutes(router);

  return router;
}
