import { onMounted, onUnmounted, ref } from 'vue';
import { Router } from 'vue-router';

import { Unsub } from '@mfl/framework';
import {
  NavigationBarMode,
  navigationBarMode,
  NavigationItem,
  navigationItemsOverride,
} from '@mfe/core-navigation';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';

import outlookSvg from './outlook.svg';
import outlookWebSvg from './outlook-web.svg';
import appleSvg from './apple.svg';
import windowsSvg from './windows.svg';
import gmailSvg from './gmail.svg';
import macMailSvg from './mac-mail.svg';
import yahooSvg from './yahoo.svg';
import otherSvg from './other.svg';

import Instructions from '../instructions-view.vue';
import OutlookForWeb from '../outlook/outlook-for-web.vue';
import OutlookForMac from '../outlook/outlook-for-mac.vue';
import OutlookForWindows from '../outlook/outlook-for-windows.vue';
import Gmail from '../gmail/gmail-instructions.vue';
import Mac from '../mac/mac-instructions.vue';
import iOS from '../ios/ios-instructions.vue';
import Yahoo from '../yahoo/yahoo-instructions.vue';
import Other from '../other/other-instructions.vue';

import strings from './instructions-navigation.strings';

const allowListMapping: Record<string, string[]> = {
  'Mac Mail': ['MAC_MAIL'],
  iPhone: ['IOS_MAIL'],
  Gmail: ['GMAIL'],
  'Outlook desktop': ['OUTLOOK', 'OUTLOOK_WEB', 'OUTLOOK_WIN', 'OUTLOOK_MAC'],
  Yahoo: ['YAHOO'],
  HTML: ['OTHER'],
};

/**
 * Self register vue-router routes
 */
export function installInstructionsRoutes(router: Router): void {
  router.addRoute({
    path: '/instructions/:signatureId',
    component: Instructions,
    children: [
      {
        path: 'outlook/for-web',
        component: OutlookForWeb,
      },
      {
        path: 'outlook/for-windows',
        component: OutlookForWindows,
      },
      {
        path: 'outlook/for-mac',
        component: OutlookForMac,
      },
      {
        path: 'gmail',
        component: Gmail,
      },
      {
        path: 'yahoo',
        component: Yahoo,
      },
      {
        path: 'mac-mail',
        component: Mac,
      },
      {
        path: 'ios-mail',
        component: iOS,
      },
      {
        path: 'other',
        component: Other,
      },
    ],
  });
}

/**
 * A vue composable for registering the navigation items
 *
 * These items are overriding the global employee-hub nav\
 * They are removed when the instructions component is unmounted
 * @param signatureId The current signature id (taken from the route)
 */
export function useInstructionsNavigation(signatureId: string) {
  const navBarReady = ref(false);

  const navItems: NavigationItem[] = [
    {
      key: 'OUTLOOK',
      text: strings.outlook,
      iconUrl: outlookSvg,
    },
    {
      key: 'OUTLOOK_WEB',
      parentKey: 'OUTLOOK',
      text: strings.outlookForWeb,
      route: `/instructions/${signatureId}/outlook/for-web`,
      iconUrl: outlookWebSvg,
    },
    {
      key: 'OUTLOOK_WIN',
      parentKey: 'OUTLOOK',
      text: strings.outlookForWindows,
      route: `/instructions/${signatureId}/outlook/for-windows`,
      iconUrl: windowsSvg,
    },
    {
      key: 'OUTLOOK_MAC',
      parentKey: 'OUTLOOK',
      text: strings.outlookForMac,
      route: `/instructions/${signatureId}/outlook/for-mac`,
      iconUrl: appleSvg,
    },
    {
      key: 'GMAIL',
      text: strings.gmail,
      route: `/instructions/${signatureId}/gmail`,
      iconUrl: gmailSvg,
    },
    {
      key: 'YAHOO',
      text: strings.yahoo,
      route: `/instructions/${signatureId}/yahoo`,
      iconUrl: yahooSvg,
    },
    {
      key: 'MAC_MAIL',
      text: strings.macMail,
      route: `/instructions/${signatureId}/mac-mail`,
      iconUrl: macMailSvg,
    },
    {
      key: 'IOS_MAIL',
      text: strings.iOSMail,
      route: `/instructions/${signatureId}/ios-mail`,
      iconUrl: macMailSvg,
    },
    {
      key: 'OTHER',
      text: strings.other,
      route: `/instructions/${signatureId}/other`,
      iconUrl: otherSvg,
    },
  ];

  const unsubs: Unsub[] = [];

  const originalNavigationBarMode = navigationBarMode.value;

  onMounted(async () => {
    try {
      const response = await employeePortalGateway.getDomainSettings({});
      const allowedInstructions = response.instructions || [];

      const filteredNavItems = navItems.filter((item) => {
        return allowedInstructions.some((allowItem) =>
          allowListMapping[allowItem]?.includes(item.key)
        );
      });

      filteredNavItems.forEach((item) => {
        unsubs.push(navigationItemsOverride.register(item));
      });
    } finally {
      navigationBarMode.value = NavigationBarMode.Locked;
      navBarReady.value = true;
    }
  });

  onUnmounted(() => {
    unsubs.forEach((unsub) => unsub());

    navigationBarMode.value = originalNavigationBarMode;
  });
  return { navBarReady };
}
