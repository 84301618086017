<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue';
import {
  WsButton,
  WsBadge,
  WsHtmlViewer,
  WsIconButton,
  WsTooltip,
} from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';
import {
  type Signature,
  employeePortalGateway,
} from '@msl/employee-portal-gateway-sdk';
import { openSignaturePreviewDialog } from './signature-preview-dialog';
import { vLoadingL } from '@mfl/common-components';

import strings from '../../employee-portal-signatures.strings';

const signatureHtml = ref<string>('');
const loading = ref<boolean>(false);

const props = defineProps<{
  signature: Signature;
  instructions: string[];
}>();

function install(signatureId: string | undefined) {
  if (!signatureId) return;
  localStorage.setItem(`signatureHtml_${signatureId}`, signatureHtml.value);
  navigateTo(`/instructions/${signatureId}`);
}

function edit(signatureId: string | undefined) {
  if (!signatureId) return;
  navigateTo(`/signature/${signatureId}/edit`);
}

onMounted(async () => {
  loading.value = true;
  try {
    const response = await employeePortalGateway.renderSignatureById({
      signatureId: props.signature.signatureId,
    });
    signatureHtml.value = response.html || '';
  } catch (error) {
    console.error('Error fetching signature HTML:', error);
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <div class="group shadow w-[575px] flex flex-col items-start rounded-lg">
    <div
      class="bg-white w-full h-[50px] px-3.5 flex justify-between items-center rounded-t-lg border-b border-b-gray-100"
    >
      <div class="font-bold text-md">{{ signature.title }}</div>

      <div class="flex gap-[10px]">
        <WsBadge
          v-if="signature.isPrimary"
          size="sm"
          label="Primary"
          aid="PRIMARY_BADGE"
          class="text-uppercase shrink-0"
        />
        <WsBadge
          v-if="signature.isReply"
          size="sm"
          label="Reply"
          aid="REPLY_BADGE"
          class="text-uppercase shrink-0"
        />
        <WsBadge
          v-if="signature.isForward"
          size="sm"
          label="Forward"
          aid="FORWARD_BADGE"
          class="text-uppercase shrink-0"
        />
      </div>
    </div>

    <div
      v-loading-l="loading"
      class="relative bg-white w-full h-[300px] p-5 overflow-auto"
    >
      <WsHtmlViewer :content="signatureHtml" scale="fit" />

      <WsIconButton
        aid="SIGNATURE_PREVIEW_ICON_BUTTON"
        size="sm"
        color="gray-400"
        class="absolute top-2 right-2 p-2.5 !text-lg opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
        @click="openSignaturePreviewDialog({ html: signatureHtml })"
      >
        <WsTooltip aid="SIGNATURE_PREVIEW_ICON_BUTTON_TOOLTIP">
          {{ strings.signaturePreviewIconButtonTooltip }}
        </WsTooltip>
        <i class="fa-light fa-magnifying-glass-plus"></i>
      </WsIconButton>
    </div>

    <div
      class="bg-white w-full h-[50px] flex justify-end items-center px-3.5 rounded-b-lg border-t border-t-gray-100"
    >
      <div
        class="flex gap-[10px] opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
      >
        <WsButton
          v-if="instructions.length > 0"
          label="Install"
          color="primary"
          size="sm"
          aid="INSTALL_BUTTON"
          @click="install(signature.signatureId)"
        />
        <WsButton
          label="Edit details"
          color="primary"
          size="sm"
          aid="EDIT_BUTTON"
          @click="edit(signature.signatureId)"
        />
      </div>
    </div>
  </div>
</template>
