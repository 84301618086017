import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';
import { onMounted, onUnmounted } from 'vue';

/**
 * Temporary\
 * Hide the core-navigation bar until links & surveys are implemented
 */
export function useDisableNavigation() {
  const originalNavigationBarMode = navigationBarMode.value;

  onMounted(() => {
    navigationBarMode.value = NavigationBarMode.Hidden;
  });

  onUnmounted(() => {
    navigationBarMode.value = originalNavigationBarMode;
  });
}
