<script setup lang="ts">
import { ref } from 'vue';
import {
  IDialog,
  WsDialogActions,
  WsSelect,
  WsSelectOption,
  WsButton,
  WsDialogForm,
  WsDialogSection,
} from '@mfl/common-components';
import type {
  EmployeeSignatureRulesDialogInput,
  EmployeeSignatureRulesDialogOutput,
} from './employee-signature-rules-dialog';
import {
  type Signature,
  employeePortalGateway,
} from '@msl/employee-portal-gateway-sdk';
import strings from '../../employee-portal-signatures.strings';

const { dialog } = defineProps<{
  dialog: IDialog<
    EmployeeSignatureRulesDialogInput,
    EmployeeSignatureRulesDialogOutput
  >;
}>();

async function submit() {
  if (
    !selectedPrimarySignature.value ||
    !selectedReplySignature.value ||
    !selectedForwardSignature.value
  ) {
    return;
  }

  await employeePortalGateway.updateEmployee({
    employee: {
      signatureId: selectedPrimarySignature.value.signatureId,
      signatureReplyId: selectedReplySignature.value.signatureId,
      signatureForwardId: selectedForwardSignature.value.signatureId,
    },
  });

  dialog.close({
    primarySignature: selectedPrimarySignature.value,
    replySignature: selectedReplySignature.value,
    forwardSignature: selectedForwardSignature.value,
  });
}

const signatures = dialog.input.signatures;
const selectedPrimarySignature = ref(signatures.find((s) => s.isPrimary));
const selectedReplySignature = ref(
  signatures.find((s) => s.isReply) ?? selectedPrimarySignature.value
);
const selectedForwardSignature = ref(
  signatures.find((s) => s.isForward) ?? selectedPrimarySignature.value
);

const optionLabel = (option: Signature) => option.title ?? '';
const optionKey = (option: Signature) => option.signatureId ?? '';
</script>

<template>
  <WsDialogForm @submit="submit">
    <WsDialogSection class="w-[500px] flex flex-col gap-y-[14px]">
      <p class="text-md">
        {{ strings.employeeSignatureRulesSettingsDialogTitle }}
      </p>

      <WsSelect
        v-model="selectedPrimarySignature"
        label="Primary"
        :option-label="optionLabel"
        :option-key="optionKey"
        aid="SELECT_PRIMARY"
      >
        <WsSelectOption
          v-for="signature in signatures"
          :key="signature.signatureId"
          :value="signature"
        >
          {{ signature.title }}
        </WsSelectOption>
      </WsSelect>

      <WsSelect
        v-model="selectedReplySignature"
        label="Reply"
        :option-label="optionLabel"
        :option-key="optionKey"
        aid="SELECT_REPLY"
      >
        <WsSelectOption
          v-for="signature in signatures"
          :key="signature.signatureId"
          :value="signature"
        >
          {{ signature.title }}
        </WsSelectOption>
      </WsSelect>

      <WsSelect
        v-model="selectedForwardSignature"
        label="Forward"
        :option-label="optionLabel"
        :option-key="optionKey"
        aid="SELECT_FORWARD"
      >
        <WsSelectOption
          v-for="signature in signatures"
          :key="signature.signatureId"
          :value="signature"
        >
          {{ signature.title }}
        </WsSelectOption>
      </WsSelect>
    </WsDialogSection>

    <WsDialogActions>
      <WsButton
        variant="outlined"
        label="Cancel"
        aid="CANCEL_RULES"
        @click="dialog.close()"
      />

      <WsButton type="submit" label="Apply" aid="SAVE_RULES" />
    </WsDialogActions>
  </WsDialogForm>
</template>
