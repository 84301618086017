export const instructionsData = {
  outlookWeb: {
    id: 'OUTLOOK_FOR_WEB',
    title: 'Outlook for Web',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'Chrome Extension',
      copy: 'Copy / Paste',
    },
    stepsByType: {
      recommended: [
        {
          text: 'Navigate to the <a href="https://chromewebstore.google.com/detail/wisestamp-email-signature/pbcgnkmbeodkmiijjfnliicelkjfcldg?pli=1" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">WiseStamp Chrome extension.</a>',
          previewImage: 'instructions/gmail/extension/step1.png',
          subSteps: [
            'A. Open the Chrome Web Store.',
            'B. Search for "WiseStamp".',
            'C. Click on the WiseStamp email signature extension.',
            'D. Click <b>Add to Chrome.</b>',
          ],
          aid: 'OUTLOOK_FOR_WEB_R_STEP_1',
        },
        {
          text: 'To confirm, click <b>Add extension.<b>',
          previewImage: 'instructions/gmail/extension/step2.png',
          aid: 'OUTLOOK_FOR_WEB_R_STEP_2',
        },
        {
          text: 'After the installation, the extension icon appears next to the right of the address bar. Go to your Outlook web and click <b>Sign in<b>.',
          previewImage: 'instructions/gmail/extension/step3.png',
          aid: 'OUTLOOK_FOR_WEB_R_STEP_3',
        },
        {
          text: 'Enter your email address, if not already recognized. Verify your email address using the One-Time Password (OTP) sent to your email.',
          previewImage: 'instructions/gmail/extension/step4.png',
          aid: 'OUTLOOK_FOR_WEB_R_STEP_4',
        },
        {
          text: 'Compose a new email in Outlook web to ensure your signature appears automatically.',
          previewImage: 'instructions/outlook-for-web/extension/step5.png',
          aid: 'OUTLOOK_FOR_WEB_R_STEP_5',
        },
      ],
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/outlook-for-web/manual/step1.png',
          copySignature: true,
          aid: 'OUTLOOK_FOR_WEB_C_STEP_1',
        },
        {
          text: 'Open your Outlook Web and compose a new email.',
          previewImage: 'instructions/outlook-for-web/manual/step2.png',
          aid: 'OUTLOOK_FOR_WEB_C_STEP_2',
        },
        {
          text: 'Paste your signature as a new signature.',
          subSteps: [
            'A. Go to the <b>Messages</b> tab, click <b>Signature</b>, and then select <b>Signatures.</b>',
            'B. In the <b>Create and edit signatures</b> section, click <b>New signature.</b>',
            'C. Enter the name for your signature.',
            'D. Go to the <b>Edit signature</b> area, and paste your copied signature.',
            'E. Click <b>Save.</b>',
          ],
          previewImage: 'instructions/outlook-for-web/manual/step7.png',
          aid: 'OUTLOOK_FOR_WEB_C_STEP_3',
        },
        {
          text: 'Assign your signature to all relevant email types (new, reply, or forward emails):',
          subSteps: [
            'A. Go to the <b>Select default signatures</b> section. From the <b>For New Messages</b> and <b>or Replies/Forwards</b> dropdown boxes, select the signature you want for each email type.',
            'B. Click <b>Save</b> and close the dialog box.',
            'C. Your signature will appear automatically in the appropriate emails.',
          ],
          previewImage: 'instructions/outlook-for-web/manual/step8.png',
          aid: 'OUTLOOK_FOR_WEB_C_STEP_4',
        },
      ],
    },
  },
  outlookWindows: {
    id: 'OUTLOOK_FOR_WINDOWS',
    title: 'Outlook for Windows',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'WiseStamp Desktop App',
      copy: 'Copy / Paste',
    },
    stepsByType: {
      recommended: [
        {
          text: 'Click <b>Copy</b> to copy your access token.',
          previewImage:
            'instructions/outlook-for-windows/desktop_app/step1.png',
          copyToken: true,
          aid: 'OUTLOOK_FOR_WINDOWS_R_STEP_1',
        },
        {
          text: 'Install the WiseStamp App.',
          subSteps: [
            'A. Download the <a href="https://storage.googleapis.com/ws-web-outlook/WiseStampInstaller.msi" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">WiseStamp App.</a>',
            'B. Double-click the downloaded file <b>(WiseStampInstaller)</b> to open the installation wizard. If a Microsoft Defender window pops up, click <b>More info</b> and then click <b>Run anyway</b>.',
            'C. Install the app using the installation wizard. On the last screen,  verify that <b>Launch WiseStamp</b> is selected. Then click <b>Close</b> to exit the wizard.',
          ],
          previewImage:
            'instructions/outlook-for-windows/desktop_app/step2.png',
          aid: 'OUTLOOK_FOR_WINDOWS_R_STEP_2',
        },
        {
          text: 'Enter your access token.',
          previewImage:
            'instructions/outlook-for-windows/desktop_app/step3.png',
          subSteps: [
            'A. Open the WiseStamp app.',
            'B. Click <b>Paste</b> to paste your access token and connect WiseStamp to your Outlook.',
            'C. A preview of your signature is displayed in the WiseStamp app. Click <b>Done</b> to close the app.',
          ],
          aid: 'OUTLOOK_FOR_WINDOWS_R_STEP_3',
        },
        {
          text: 'Assign your signature to all relevant email types (new, reply, or forward emails):',
          previewImage:
            'instructions/outlook-for-windows/desktop_app/step4.png',
          subSteps: [
            'A. Open Outlook and compose a new email.',
            'B. Go to the <b>Messages</b> tab, click <b>Signature</b>, and then select <b>Signatures</b>.',
            'C. Go to the <b>Choose default signature</b> section. From the <b>New messages</b> and <b>Replies/forwards</b> dropdown boxes, select the signature you want for each email type.',
            'D. Click <b>Save</b> and close the dialog box.',
            'E. Your signature will appear automatically in the appropriate emails.',
          ],
          aid: 'OUTLOOK_FOR_WINDOWS_R_STEP_4',
        },
      ],
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/outlook-for-windows/manual/step1.png',
          copySignature: true,
          aid: 'OUTLOOK_FOR_WINDOWS_C_STEP_1',
        },
        {
          text: 'Open your Outlook and compose a new email.',
          previewImage: 'instructions/outlook-for-windows/manual/step2.png',
          aid: 'OUTLOOK_FOR_WINDOWS_C_STEP_2',
        },
        {
          text: 'Create a new signature',
          previewImage: 'instructions/outlook-for-windows/manual/step4.png',
          subSteps: [
            'A. Go to the <b>Messages</b> tab, click <b>Signature</b>, and then select <b>Signatures</b>.',
            'B. In the <b>Select signature to edit</b> section, click <b>New</b>.',
            'C. Enter the name for your signature and click <b>OK</b>.',
          ],
          aid: 'OUTLOOK_FOR_WINDOWS_C_STEP_3',
        },
        {
          text: 'Go to the <b>Edit signature</b> area, and paste your copied signature.',
          previewImage: 'instructions/outlook-for-windows/manual/step4.png',
          aid: 'OUTLOOK_FOR_WINDOWS_C_STEP_4',
        },
        {
          text: 'Assign your signature to all relevant email types (new, reply, or forward emails)',
          previewImage: 'instructions/outlook-for-windows/manual/step4.png',
          subSteps: [
            'A. Go to the <b>Choose default signature</b> section. From the <b>New messages</b> and <b>Replies/forwards</b> dropdown boxes, select the signature you want for each email type.',
            'B. Click <b>OK</b>.',
            'C. Your signature will appear automatically in the appropriate emails.',
          ],
          aid: 'OUTLOOK_FOR_WINDOWS_C_STEP_5',
        },
      ],
    },
  },
  outlookMac: {
    id: 'OUTLOOK_FOR_MAC',
    title: 'Outlook for Mac',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'WiseStamp Desktop App',
      copy: 'Copy / Paste',
    },
    stepsByType: {
      recommended: [
        {
          text: 'Click <b>Copy</b> to copy your access token.',
          previewImage: 'instructions/outlook-for-mac/desktop_app/step1.png',
          copyToken: true,
          aid: 'OUTLOOK_FOR_MAC_R_STEP_1',
        },
        {
          text: 'Install the WiseStamp app from the <a href="https://apps.apple.com/il/app/wisestamp-signature-manager/id1589168316?mt=12" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">App Store</a>.',
          subSteps: [
            'A. Open the App Store app.',
            'B. Search for "WiseStamp".',
            'C. Click <b>GET</b> and then click <b>INSTALL</b>.',
            'D. Enter your credentials and click <b>Get</b>.',
          ],
          previewImage: 'instructions/outlook-for-mac/desktop_app/step2.png',
          aid: 'OUTLOOK_FOR_MAC_R_STEP_2',
        },
        {
          text: 'Enter your access token.',
          previewImage: 'instructions/outlook-for-mac/desktop_app/step3.png',
          subSteps: [
            'A. Open the WiseStamp app.',
            'B. Click <b>Paste</b> to paste your access token and connect WiseStamp to your Outlook.',
          ],
          aid: 'OUTLOOK_FOR_MAC_R_STEP_3',
        },
        {
          text: 'Select <b>Outlook.</b>',
          previewImage: 'instructions/outlook-for-mac/desktop_app/step4.png',
          subSteps: [
            'A. A preview of your signature is displayed in the WiseStamp app.',
            'B. Select <b>Use in Outlook</b>.',
            'C. Click <b>Done</b> to close the app.',
          ],
          aid: 'OUTLOOK_FOR_MAC_R_STEP_4',
        },
        {
          text: 'Assign your signature to all relevant email types (new, reply, or forward emails):',
          previewImage: 'instructions/outlook-for-mac/desktop_app/step4.png',
          subSteps: [
            'A. Open Outlook and compose a new email.',
            'B. Above the new email, click <b>Signature</b>, and then select <b>Manage Signatures</b>.',
            'C. Go to the <b>Choose default signature</b> section. From the <b>New messages</b> and <b>Replies/forwards</b> dropdown boxes, select the signature you want for each email type.',
            'D. Close the Manage Signature popup.',
            'E. Your signature will appear automatically in the appropriate emails.',
          ],
          aid: 'OUTLOOK_FOR_MAC_R_STEP_5',
        },
      ],
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/outlook-for-mac/manual/step1.png',
          copySignature: true,
          aid: 'OUTLOOK_FOR_MAC_C_STEP_1',
        },
        {
          text: 'Open your Outlook and compose a new email.',
          previewImage: 'instructions/outlook-for-mac/manual/step2.png',
          aid: 'OUTLOOK_FOR_MAC_C_STEP_2',
        },
        {
          text: 'Paste your signature as a new Outlook signature.',
          previewImage: 'instructions/outlook-for-mac/manual/step3.png',
          subSteps: [
            'A. Above the new email, click <b>Signature</b>, and then select <b>Manage Signatures</b>.',
            'B. In the <b>Edit signature</b> section, click the + icon to add a new signature.',
            'C. Enter the name for your signature.',
            'D. In the lower panel, replace the current text (typically your name) with your copied signature. Select the text, click the <b>Clipboard</b> icon, and select <b>Paste.</b>',
            'E. Immediately after pasting, the <b>Paste</b> icon appears at the end of your signature. Click the <b>Paste</b> icon and select <b>Keep Source Formatting.</b>',
            'F. Click <b>Save</b> and close the <b>Edit Signature</b> popup.',
          ],
          aid: 'OUTLOOK_FOR_MAC_C_STEP_3',
        },
        {
          text: 'Assign your signature to all relevant email types (new, reply, or forward emails)',
          previewImage: 'instructions/outlook-for-mac/manual/step4.png',
          subSteps: [
            'A. Go to the <b>Choose default signature</b> section. From the <b>New messages</b> and <b>Replies/forwards</b> dropdown boxes, select the signature you want for each email type.',
            'B. Close the <b>Manage Signature</b> popup.',
            'C. Your signature will appear automatically in the appropriate emails.',
          ],
          aid: 'OUTLOOK_FOR_MAC_C_STEP_4',
        },
      ],
    },
  },
  gmail: {
    id: 'GMAIL',
    title: 'Gmail',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'Chrome extension',
      copy: 'Copy / Paste',
    },
    stepsByType: {
      recommended: [
        {
          text: 'Navigate to the <a href="https://chromewebstore.google.com/detail/wisestamp-email-signature/pbcgnkmbeodkmiijjfnliicelkjfcldg?pli=1" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">WiseStamp Chrome extension.</a>',
          previewImage: 'instructions/gmail/extension/step1.png',
          subSteps: [
            'A. Open the Chrome Web Store.',
            'B. Search for "WiseStamp".',
            'C. Click on the WiseStamp email signature extension.',
            'D. Click <b>Add to Chrome.</b>',
          ],
          aid: 'GMAIL_R_STEP_1',
        },
        {
          text: 'To confirm, click <b>Add extension.<b>',
          previewImage: 'instructions/gmail/extension/step2.png',
          aid: 'GMAIL_R_STEP_2',
        },
        {
          text: 'After the installation, the extension icon appears next to the right of the address bar. Go to your Gmail and click <b>Sign in<b>.',
          previewImage: 'instructions/gmail/extension/step3.png',
          aid: 'GMAIL_R_STEP_3',
        },
        {
          text: 'Enter your email address, if not already recognized. Verify your email address using the One-Time Password (OTP) sent to your email.',
          previewImage: 'instructions/gmail/extension/step4.png',
          aid: 'GMAIL_R_STEP_4',
        },
        {
          text: 'Compose a new email in Gmail to ensure your signature appears automatically.',
          previewImage: 'instructions/gmail/extension/step5.png',
          aid: 'GMAIL_R_STEP_5',
        },
      ],
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/gmail/manual/step1.png',
          copySignature: true,
          aid: 'GMAIL_C_STEP_1',
        },
        {
          text: 'Sign in to your Gmail account. Click the <b>Settings</b> icon (⚙️) on the top-right corner and then click <b>See all settings</b>.',
          previewImage: 'instructions/gmail/manual/step2.png',
          aid: 'GMAIL_C_STEP_2',
        },
        {
          text: 'In the <b>General</b> tab, scroll down to the <b>Signature</b> section and click <b>Create new</b>.',
          previewImage: 'instructions/gmail/manual/step3.png',
          aid: 'GMAIL_C_STEP_3',
        },
        {
          text: 'Enter a name for your signature, and click <b>Create</b>.',
          previewImage: 'instructions/gmail/manual/step4.png',
          aid: 'GMAIL_C_STEP_4',
        },
        {
          text: 'Paste the copied signature into the editor.',
          previewImage: 'instructions/gmail/manual/step5.png',
          aid: 'GMAIL_C_STEP_5',
        },
        {
          text: 'Under <b>Signature defaults</b>, select your new signature for <b>New Emails Use</b> and <b>On Reply/Forward Use</b>.',
          previewImage: 'instructions/gmail/manual/step6.png',
          aid: 'GMAIL_C_STEP_6',
        },
        {
          text: 'Scroll to the bottom of the page and click <b>Save Changes</b>.',
          previewImage: 'instructions/gmail/manual/step7.png',
          aid: 'GMAIL_C_STEP_7',
        },
        {
          text: 'Compose a new email to ensure that your signature appears correctly.',
          previewImage: 'instructions/gmail/manual/step8.png',
          aid: 'GMAIL_C_STEP_8',
        },
      ],
    },
  },
  mac: {
    id: 'MAC',
    title: 'Apple Mail for Mac',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'WiseStamp Desktop App',
      copy: 'Copy / Paste',
    },
    stepsByType: {
      recommended: [
        {
          text: 'Open the Mail app.',
          previewImage: 'instructions/mac/desktop_app/step1.png',
          aid: 'MAC_R_STEP_1',
        },
        {
          text: 'Go to <b>Mail</b> and select <b>Settings / Preferences.</b>',
          previewImage: 'instructions/mac/desktop_app/step2.gif',
          aid: 'MAC_R_STEP_2',
        },
        {
          text: 'Go to the <b>Signatures</b> tab.',
          previewImage: 'instructions/mac/desktop_app/step2.gif',
          aid: 'MAC_R_STEP_3',
        },
        {
          text: 'Click + to create a new signature. Enter the signature name <b>Teams Signature</b> and press <b>Enter.</b> <br/><b>IMPORTANT:</b> The name is case-sensitive and must be copied exactly as written.',
          previewImage: 'instructions/mac/desktop_app/step3.gif',
          aid: 'MAC_R_STEP_4',
        },
        {
          text: 'Drag the <b>Teams Signature</b> name to the email account you want to use located on the left panel.',
          previewImage: 'instructions/mac/desktop_app/step4.gif',
          aid: 'MAC_R_STEP_5',
        },
        {
          text: 'Set <b>Teams Signature</b> as the default signature for new and replies/forwards emails.',
          subSteps: [
            'A. Click the email account.',
            'B. At the bottom of the screen, click the <b>Choose Signature</b> dropdown list and select <b>Teams Signature.</b>',
          ],
          previewImage: 'instructions/mac/desktop_app/step5.gif',
          aid: 'MAC_R_STEP_6',
        },
        {
          text: 'Close the Mail app. Go to <b>Mail</b> and select <b>Quit Mail.</b>',
          previewImage: 'instructions/mac/desktop_app/step6.gif',
          aid: 'MAC_R_STEP_7',
        },
        {
          text: 'Open <b>System Settings</b>.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_8',
        },
        {
          text: 'Click your name (Apple ID) in the sidebar.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_9',
        },
        {
          text: 'Click on <b>Apple ID</b> in the sidebar.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_10',
        },
        {
          text: 'Select <b>See All</b> under <b>iCloud Drive</b>.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_11',
        },
        {
          text: 'Go to <b>Apps Syncing to iCloud Drive</b>.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_12',
        },
        {
          text: 'Locate <b>Mail</b> in the list and toggle it <b>off</b>.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_13',
        },
        {
          text: 'Click <b>Done</b> to save your changes.',
          previewImage: 'instructions/mac/desktop_app/step7.gif',
          aid: 'MAC_R_STEP_14',
        },
        {
          text: 'Install the WiseStamp app from the <a href="https://apps.apple.com/us/app/wisestamp-email-signatures/id1091441869" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">App Store</a>.',
          subSteps: [
            'A. Open the App Store app.',
            'B. Search for "WiseStamp".',
            'C. Click <b>GET</b> and then click <b>INSTALL.</b>',
            'D. Enter your credentials and click <b>Get.</b>',
          ],
          previewImage: 'instructions/mac/desktop_app/step13.png',
          aid: 'MAC_R_STEP_15',
        },
        {
          text: 'Click <b>Copy</b> to copy your access token.',
          copyToken: true,
          previewImage: 'instructions/mac/desktop_app/step14.png',
          aid: 'MAC_R_STEP_16',
        },
        {
          text: 'Enter your access token.',
          subSteps: [
            'A. Open the WiseStamp app.',
            'B. Click <b>Paste</b> to paste your access token and connect WiseStamp to your Outlook.',
          ],
          previewImage: 'instructions/mac/desktop_app/step15.png',
          aid: 'MAC_R_STEP_17',
        },
        {
          text: 'Select <b>Mac Mail.</b>',
          subSteps: [
            'A. A preview of your signature is displayed in the WiseStamp app.',
            'B. Select <b>Use in Mac Mail.</b>',
            'C. On the screen that opens, ensure that the <b>Signatures folder</b> is selected and click <b>Open.</b> If the Signatures folder doesn\'t open automatically, click <a href="https://support.wisestamp.com/hc/en-us/articles/8473537010717-Finding-the-Signatures-folder-on-your-Mac" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">here</a> for instructions on locating the correct path.',
          ],
          previewImage: 'instructions/mac/desktop_app/step17.gif',
          aid: 'MAC_R_STEP_18',
        },
        {
          text: 'Click the <b>Refresh</b> icon to save your settings.',
          previewImage: 'instructions/mac/desktop_app/step18.gif',
          aid: 'MAC_R_STEP_19',
        },
        {
          text: 'Verify your signature.',
          subSteps: [
            'A. Re-open the <b>Mail</b> app.',
            'B. Compose a test email.',
            'C. Verify that your signature appears at the bottom of the draft.',
          ],
          previewImage: 'instructions/mac/desktop_app/step19.png',
          aid: 'MAC_R_STEP_20',
        },
        {
          text: 'If you make changes to your WiseStamp signature in the future, repeat steps 7 and 19 to update your signature in the Mail app.',
          previewImage: 'instructions/mac/desktop_app/step19.png',
          aid: 'MAC_R_STEP_21',
        },
      ],
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/mac/manual/step1.png',
          copySignature: true,
          aid: 'MAC_C_STEP_1',
        },
        {
          text: 'Open the Mail app.',
          previewImage: 'instructions/mac/manual/step2.png',
          aid: 'MAC_C_STEP_2',
        },
        {
          text: 'Go to <b>Mail</b> and select <b>Settings / Preferences.</b>',
          previewImage: 'instructions/mac/manual/step3.gif',
          aid: 'MAC_C_STEP_3',
        },
        {
          text: 'Go to the <b>Signatures</b> tab.',
          previewImage: 'instructions/mac/manual/step3.gif',
          aid: 'MAC_C_STEP_4',
        },
        {
          text: 'Click + to create a new signature and enter a name for the signature.',
          previewImage: 'instructions/mac/manual/step4.gif',
          aid: 'MAC_C_STEP_5',
        },
        {
          text: 'Clear <b>Always match my default message font.</b>',
          previewImage: 'instructions/mac/manual/step5.gif',
          aid: 'MAC_C_STEP_6',
        },
        {
          text: 'Paste your email signature (cmd⌘ + v) into the right panel. <br/>It’s normal not to see images here.',
          previewImage: 'instructions/mac/manual/step6.gif',
          aid: 'MAC_C_STEP_7',
        },
        {
          text: 'Drag the name of the new signature to the email account you want to use located on the left panel.',
          previewImage: 'instructions/mac/manual/step7.gif',
          aid: 'MAC_C_STEP_8',
        },
        {
          text: 'Set the new signature as the default signature for new and replies/forwards emails.',
          subSteps: [
            'A. Click the email account.',
            'B. At the bottom of the screen, click the <b>Choose Signature</b> dropdown list and select the new signature.',
          ],
          previewImage: 'instructions/mac/manual/step7.gif',
          aid: 'MAC_C_STEP_9',
        },
        {
          text: 'To save, close the pop-up window.',
          previewImage: 'instructions/mac/manual/step8.png',
          aid: 'MAC_C_STEP_10',
        },
        {
          text: 'Compose a new email in Mail on Mac and the signature will appear.',
          previewImage: 'instructions/mac/manual/step8.png',
          aid: 'MAC_C_STEP_11',
        },
      ],
    },
  },
  ios: {
    id: 'IOS',
    title: 'Apple Mail for iOS',
    steps: [
      {
        text: 'Click <b>Send</b> to send your signature to your iPhone / iPad.',
        previewImage: 'instructions/ios/step1.png',
        sendSignature: true,
        aid: 'IOS_R_STEP_1',
      },
      {
        text: "Open the email through your iPhone/iPad's Mail app. If you don’t see it in your inbox, check the <b>Promotions</b> or <b>Spam</b> folder, and move it to your primary inbox if necessary.",
        previewImage: 'instructions/ios/step2.jpg',
        aid: 'IOS_R_STEP_2',
      },
      {
        text: 'From the email, select all the content in the email and tap <b>Copy.</b> <br/><b>Important:</b> Missing any part may cause formatting issues.',
        previewImage: 'instructions/ios/step3.gif',
        aid: 'IOS_R_STEP_3',
      },
      {
        text: 'Paste and format your signature.',
        subSteps: [
          'A: Go to <b>Settings > Mail > Signature</b>',
          'B: Tap the signature field, and paste the copied signature.',
          'C: Shake your phone to activate the <b>Shake to Undo</b> feature and tap Undo to restore proper formatting.',
        ],
        previewImage: 'instructions/ios/step4.gif',
        aid: 'IOS_R_STEP_4',
      },
      {
        text: 'Open the Mail app and compose a new email to confirm that your signature displays as intended.',
        previewImage: 'instructions/ios/step5.gif',
        aid: 'IOS_R_STEP_5',
      },
    ],
    stepsByTypeOptions: {},
    stepsByType: {},
  },
  yahoo: {
    id: 'YAHOO',
    title: 'Yahoo',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'Chrome extension',
      copy: 'Copy / Paste',
    },
    stepsByType: {
      recommended: [
        {
          text: 'Navigate to the <a href="https://chromewebstore.google.com/detail/wisestamp-email-signature/pbcgnkmbeodkmiijjfnliicelkjfcldg?pli=1" style="text-decoration: underline; color: rgb(var(--color-primary));" target="_blank">WiseStamp Chrome extension.</a>',
          previewImage: 'instructions/yahoo/extension/step1.png',
          subSteps: [
            'A. Open the Chrome Web Store.',
            'B. Search for "WiseStamp".',
            'C. Click on the WiseStamp email signature extension.',
            'D. Click <b>Add to Chrome.</b>',
          ],
          aid: 'YAHOO_R_STEP_1',
        },
        {
          text: 'To confirm, click <b>Add extension.</b>',
          previewImage: 'instructions/yahoo/extension/step2.png',
          aid: 'YAHOO_R_STEP_2',
        },
        {
          text: 'After the installation, the extension icon appears next to the right of the address bar. Go to Yahoo Mail and click <b>Sign in.</b>',
          previewImage: 'instructions/yahoo/extension/step3.png',
          aid: 'YAHOO_R_STEP_3',
        },
        {
          text: 'Enter your email address, if not already recognized. Verify your email address using the One-Time Password (OTP) sent to your email.',
          previewImage: 'instructions/yahoo/extension/step4.png',
          aid: 'YAHOO_R_STEP_4',
        },
        {
          text: 'Compose a new email in Yahoo Mail to ensure your signature appears automatically.',
          previewImage: 'instructions/yahoo/extension/step5.png',
          aid: 'YAHOO_R_STEP_5',
        },
      ],
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/yahoo/manual/step1.png',
          copySignature: true,
          aid: 'YAHOO_C_STEP_1',
        },
        {
          text: 'Sign in to your Yahoo Mail account. Click the <b>Settings</b> icon (⚙️) on the top-right corner and select <b>More Settings</b>.',
          previewImage: 'instructions/yahoo/manual/step3.png',
          aid: 'YAHOO_C_STEP_2',
        },
        {
          text: 'On the left panel, click <b>Writing email.</b>',
          previewImage: 'instructions/yahoo/manual/step4.png',
          aid: 'YAHOO_C_STEP_3',
        },
        {
          text: 'Scroll down to the <b>Signature</b> section and enable the <b>Signature</b> toggle.',
          previewImage: 'instructions/yahoo/manual/step5.png',
          aid: 'YAHOO_C_STEP_4',
        },
        {
          text: 'Paste your copied signature into the signature editor under the toggle.',
          previewImage: 'instructions/yahoo/manual/step6.png',
          aid: 'YAHOO_C_STEP_5',
        },
        {
          text: 'Click <b>Save</b> at the bottom to apply your new signature.',
          previewImage: 'instructions/yahoo/manual/step6.png',
          aid: 'YAHOO_C_STEP_6',
        },
        {
          text: 'Compose a new email and your signature will appear automatically.',
          previewImage: 'instructions/yahoo/manual/step7.png',
          aid: 'YAHOO_C_STEP_7',
        },
        {
          text: 'To change the assignment of your signature for new, reply, or forward emails, return to the Yahoo Mail settings and define as needed.',
          previewImage: 'instructions/yahoo/manual/step7.png',
          aid: 'YAHOO_C_STEP_8',
        },
      ],
    },
  },
  other: {
    id: 'OTHER',
    title: 'Other',
    steps: [],
    stepsByTypeOptions: {
      recommended: 'Copy / Paste',
      copy: 'HTML Code',
    },
    stepsByType: {
      copy: [
        {
          text: 'Click <b>Copy</b> to copy your signature’s HTML code.',
          previewImage: 'instructions/other/step1.png',
          copyHtml: true,
          aid: 'OTHER_R_STEP_1',
        },
        {
          text: 'Sign in to your email account.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_R_STEP_2',
        },
        {
          text: 'Go to the <b>settings</b> or <b>preferences</b> menu in your email app and locate the <b>Signature</b> or <b>Email Signature</b> section.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_R_STEP_3',
        },
        {
          text: 'Open the signature editor, switch to the <b>HTML</b> or <b>Source Code</b> view (if available), and paste the copied HTML code.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_R_STEP_4',
        },
        {
          text: 'Click <b>Save</b> or <b>Apply</b> to confirm your changes.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_R_STEP_5',
        },
        {
          text: 'Compose a new email to verify that your signature is displayed as intended.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_R_STEP_6',
        },
      ],
      recommended: [
        {
          text: 'Click <b>Copy</b> to copy your signature.',
          previewImage: 'instructions/other/step1.png',
          copySignature: true,
          aid: 'OTHER_C_STEP_1',
        },
        {
          text: 'Sign in to your email account.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_C_STEP_2',
        },
        {
          text: 'Go to the <b>settings</b> or <b>preferences</b> menu and locate the <b>Signature</b> or <b>Email Signature</b> section.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_C_STEP_3',
        },
        {
          text: 'Open the signature editor and paste the copied signature directly into the editor.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_C_STEP_4',
        },
        {
          text: 'Click <b>Save</b> or <b>Apply</b> to confirm your changes.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_C_STEP_5',
        },
        {
          text: 'Compose a new email to verify that your signature is displayed as intended.',
          previewImage: 'instructions/other/step1.png',
          aid: 'OTHER_C_STEP_6',
        },
      ],
    },
  },
};
