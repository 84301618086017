<script setup lang="ts">
import { RouterView } from 'vue-router';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';
import { onMounted } from 'vue';
import { auth4Sdks } from '@mfl/employee-portal-shell';
import { useDisableNavigation } from '../src/navigation';

useDisableNavigation();

onMounted(async () => {
  await employeePortalGateway.init(auth4Sdks());
});
</script>

<template>
  <div class="h-full flex flex-col">
    <RouterView />
  </div>
</template>
