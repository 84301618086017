import strings from '../../employee-portal-signatures.strings';

export type SignaturePreviewDialogInput = {
  html: string;
};

export type SignaturePreviewDialogOutput = void;

import { openDialog } from '@mfl/common-components';
import SignaturePreviewDialog from './signature-preview-dialog.vue';

export async function openSignaturePreviewDialog(
  input: SignaturePreviewDialogInput
): Promise<SignaturePreviewDialogOutput> {
  return await openDialog<
    SignaturePreviewDialogInput,
    SignaturePreviewDialogOutput
  >({
    aid: 'SIGNATURE_PREVIEW_DIALOG',
    headerText: strings.signaturePreviewDialogHeaderText,
    component: SignaturePreviewDialog,
    input,
  });
}
