export default {
  outlook: 'Outlook',
  outlookForWeb: 'For Web',
  outlookForWindows: 'For Windows',
  outlookForMac: 'For Mac',
  gmail: 'Gmail',
  yahoo: 'Yahoo',
  macMail: 'Apple Mail for Mac',
  iOSMail: 'Apple Mail for iOS',
  other: 'Other',
};
