export default {
  header: 'Welcome to employee-portal-signatures',
  buttonLabel: 'CC Button',
  headerBackButton: 'Back',
  signaturesViewTitle: 'Signatures',
  signaturesViewEditDetailsButton: 'Edit my details',
  signaturesViewNoSignaturesText: 'There is no assigned signature',
  signaturePreviewIconButtonTooltip: 'Preview actual size',
  signaturePreviewDialogHeaderText: 'Signature preview',
  signatureEditPreviewNewMessage: 'New Message',
  signatureEditPreviewTo: 'To:',
  signatureEditPreviewSubject: 'Subject:',
  signatureEditSignatureDetailsText: 'Signature Details',
  signatureEditPreviewBtnSaveChanges: 'Save changes',
  signatureEditPreviewBtnSaved: 'Saved!',
  signatureEditPreviewBtnAllSignatures: 'All signatures',
  signatureEditFieldReadonlyTooltip:
    'This field is locked by your admin and cannot be edited.',
  profileTitle: 'My details',
  profileContactDetailsTitle: 'Contact details',
  profileContactSocialLinksTitle: 'Social links',
  profileContactBtnName: 'Name',
  profileContactBtnEmail: 'Email',
  profileContactBtnSaveChanges: 'Save changes',
  profileContactBtnSaved: 'Saved!',
  profilePhotoUploadUnexpectedError: 'Unexpected error',
  employeeSignatureRulesSettingsBtnText: 'Settings',
  employeeSignatureRulesSettingsDialogHeader: 'Signatures Settings',
  employeeSignatureRulesSettingsDialogTitle:
    'Select signature for email types:',
};
