<script setup lang="ts">
import { WsButton } from '@mfl/common-components';

import strings from '../employee-portal-signatures.strings';

defineProps<{
  href: string;
}>();

const emit = defineEmits<{
  click: [event: PointerEvent];
}>();
</script>

<template>
  <WsButton
    variant="text"
    color="gray-500"
    icon="fa-regular fa-chevron-left"
    aid="INSTRUCTIONS_HEADER_BACK_BUTTON"
    :href="href"
    @click.prevent="emit('click', $event)"
  >
    {{ strings.headerBackButton }}
  </WsButton>
</template>
