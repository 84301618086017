import strings from '../../employee-portal-signatures.strings';
import { type Signature } from '@msl/employee-portal-gateway-sdk';
import { openDialog } from '@mfl/common-components';
import EmployeeSignatureRulesDialog from './employee-signature-rules-dialog.vue';

export type EmployeeSignatureRulesDialogInput = {
  signatures: Signature[];
};

export type EmployeeSignatureRulesDialogOutput = {
  primarySignature: Signature;
  replySignature: Signature;
  forwardSignature: Signature;
};

export async function openEmployeeSignatureRulesDialog(
  input: EmployeeSignatureRulesDialogInput
): Promise<EmployeeSignatureRulesDialogOutput | undefined> {
  return await openDialog<
    EmployeeSignatureRulesDialogInput,
    EmployeeSignatureRulesDialogOutput
  >({
    aid: 'EMPLOYEE_SIGNATURE_RULES_DIALOG',
    headerText: strings.employeeSignatureRulesSettingsDialogHeader,
    component: EmployeeSignatureRulesDialog,
    input,
  });
}
