<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import strings from '../../employee-portal-signatures.strings';

import {
  toast,
  ToastStatus,
  WsButton,
  WsInput,
  WsSelect,
  WsSelectOption,
  WsSelectOptions,
  WsSelectPanel,
  WsSelectTrigger,
  WsTooltip,
} from '@mfl/common-components';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';
import {
  navigateTo,
  preventPageUnload,
  releasePageUnload,
} from '@mfl/framework';
import { currentUser } from '@mfl/employee-portal-shell';

import ProfilePhoto from '../../components/profile-photo.vue';
import SignaturePreview from './components/signature-preview.vue';

const route = useRoute();

const signatureHtml = ref<string>('');
const signatureId = ref<string>('');

type EmployeeField = {
  property: string;
  value: string;
  label: string;
  fieldType: string;
  isEditable: boolean;
  locked?: boolean;
  values?: string[];
};

const originalFields = reactive<EmployeeField[]>([]);
const fields = reactive<EmployeeField[]>([]);
const employeeMacrosInUse = ref<string[]>([]);
const isSaved = ref<boolean>(false);
const isSaving = ref<boolean>(false);

function getOriginalFieldValue(property: string): string {
  return (
    originalFields.find((field) => field.property === property)?.value ?? ''
  );
}

function getFieldValue(property: string): string {
  return fields.find((field) => field.property === property)?.value ?? '';
}

const name = computed<string>(() => {
  return getOriginalFieldValue('name');
});

const hasChanges = computed<boolean>(() => {
  return fields.some(
    (field) => field.value !== getOriginalFieldValue(field.property)
  );
});

const buttonLabel = computed<string>(() => {
  if (isSaved.value) {
    return strings.signatureEditPreviewBtnSaved;
  }

  return strings.signatureEditPreviewBtnSaveChanges;
});

watch(hasChanges, (changed) => {
  if (changed) {
    isSaved.value = false;
  }
});

async function saveChanges() {
  isSaving.value = true;

  const data = fields
    .filter(
      (field) =>
        field.isEditable &&
        field.value !== getOriginalFieldValue(field.property)
    )
    .reduce(
      (result, field) => {
        result[field.property] = field.value ?? '';
        return result;
      },
      {} as Record<string, string>
    );

  const { name, email, ...employeeData } = data;

  try {
    const response = await employeePortalGateway.updateEmployee({
      employee: {
        name,
        employeeData,
      },
    });

    if (response.success) {
      isSaved.value = true;

      originalFields.forEach((field: EmployeeField) => {
        field.value = getFieldValue(field.property);
      });

      if (name) {
        currentUser.setName(name);
      }

      void fetchSignatureById(signatureId.value).catch(() => {
        toast({
          message: 'Failed to refresh signature preview',
          status: ToastStatus.Error,
          aid: 'SIGNATURE_PREVIEW_REFRESH_FAILED',
        });
      });
    } else {
      console.error('Failed to save changes');
    }
  } catch (error) {
    console.error(error);
  }

  isSaving.value = false;

  releasePageUnload();
}

async function fetchSignatureById(signatureId: string) {
  const response = await employeePortalGateway.renderSignatureById({
    signatureId,
  });

  signatureHtml.value = response?.html ?? '';
  employeeMacrosInUse.value = response?.macrosInUse?.employee ?? [];
}

function getSortPriority(field: EmployeeField): number {
  // 0 => the highest priority (goes first), larger => lower priority (goes later)
  if (field.property === 'photo') return 0; // photo first
  if (field.property.endsWith('_link')) return 2; // _link last
  return 1; // everything else in between
}

async function fetchFields() {
  const response = (await employeePortalGateway.getFields({})) as {
    fields: EmployeeField[];
  };

  const data = (
    employeeMacrosInUse.value.length > 0
      ? response.fields.filter((field: EmployeeField) =>
          employeeMacrosInUse.value.includes(field.property)
        )
      : response.fields
  ).sort(
    (a: EmployeeField, b: EmployeeField) =>
      getSortPriority(a) - getSortPriority(b)
  );

  originalFields.push(...structuredClone(data));
  fields.push(...structuredClone(data));
}

async function loadSignatureDetails() {
  signatureId.value = route.params.signatureId as string;

  if (!signatureId.value) {
    console.error('invalid signatureId');
    return;
  }

  try {
    await fetchSignatureById(signatureId.value);
    await fetchFields();
  } catch (error) {
    console.error(error);
  }
}

function allSignatures() {
  navigateTo(`/signatures`);
}

onMounted(async () => {
  await loadSignatureDetails();
});

onUnmounted(() => {
  releasePageUnload();
});
</script>

<template>
  <div class="flex h-full flex-nowrap">
    <div
      class="flex h-full flex-col w-[380px] border-r border-gray-100 flex-nowrap"
    >
      <div class="px-4 pt-4 flex items-center gap-2 text-md">
        <WsButton
          :label="strings.signatureEditPreviewBtnAllSignatures"
          color="gray-500"
          icon="arrow_back"
          variant="text"
          aid="BACK_TO_ALL_SIGNATURES_BUTTON"
          @click="allSignatures"
        />
      </div>

      <h2 class="text-xl font-bold text-gray-500 px-6 py-4">
        {{ strings.signatureEditSignatureDetailsText }}
      </h2>

      <form
        class="flex-grow overflow-y-auto px-6 pb-4"
        @submit.prevent="saveChanges"
      >
        <template v-for="field in fields" :key="field.property">
          <ProfilePhoto
            v-if="field.property === 'photo' && field.fieldType === 'image'"
            :aid="`FIELD_${field.property.toUpperCase()}`"
            :readonly="!field.isEditable"
            :name="name"
            :photo="field.value"
            class="mb-[14px]"
            @done="
              field.value = $event.imageUrl;
              preventPageUnload();
            "
          />

          <WsInput
            v-else-if="field.fieldType === 'text'"
            v-model="field.value"
            :aid="`FIELD_${field.property.toUpperCase()}`"
            :readonly="!field.isEditable"
            :label="field.label"
            class="pb-[14px]"
            @update:model-value="preventPageUnload()"
          >
            <WsTooltip
              v-if="!field.isEditable"
              position="right"
              :aid="`FIELD_${field.property.toUpperCase()}_READONLY_TOOLTIP`"
            >
              {{ strings.signatureEditFieldReadonlyTooltip }}
            </WsTooltip>
          </WsInput>

          <WsSelect
            v-else-if="field.fieldType === 'dropdown'"
            v-model="field.value"
            :aid="`FIELD_${field.property.toUpperCase()}`"
            :readonly="!field.isEditable"
            :label="field.label"
            :option-label="(l) => l"
            :option-key="(k) => k"
            class="mb-5"
            advanced
            @update:model-value="preventPageUnload()"
          >
            <WsSelectTrigger>
              <template v-if="!field.isEditable" #append>
                <WsTooltip
                  position="right"
                  :aid="`FIELD_${field.property.toUpperCase()}_READONLY_TOOLTIP`"
                >
                  {{ strings.signatureEditFieldReadonlyTooltip }}
                </WsTooltip>
              </template>
            </WsSelectTrigger>

            <WsSelectPanel>
              <WsSelectOptions>
                <WsSelectOption
                  v-for="(item, index) in field.values"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </WsSelectOption>
              </WsSelectOptions>
            </WsSelectPanel>
          </WsSelect>
        </template>
      </form>

      <div
        class="sticky bottom-0 bg-white px-6 py-4 border-t border-gray-100 flex justify-end"
      >
        <WsButton
          :label="buttonLabel"
          :disabled="!hasChanges"
          :loading="isSaving"
          color="primary"
          class="w-auto"
          size="sm"
          aid="SAVE_CHANGES"
          @click="saveChanges"
        />
      </div>
    </div>

    <div
      class="flex-grow flex justify-center h-full bg-primary-50 p-8 pt-[110px] w-[575px]"
    >
      <SignaturePreview :signature-html="signatureHtml" />
    </div>
  </div>
</template>
