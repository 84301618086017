<template>
  <div class="flex items-start h-screen">
    <div class="me-10 max-w-[400px] h-full border-e border-gray-100">
      <div class="border-b border-gray-100 p-4 text-lg font-bold">
        {{ title }}
      </div>
      <div
        v-if="stepsByTypeOptions"
        class="p-5 text-sm font-semibold flex items-center gap-1"
      >
        <label for="instructionType">Install your signature with</label>
        <WsSelect
          id="instructionType"
          v-model="selectedType"
          variant="text"
          underline
          :option-label="optionLabel"
          :option-key="optionKey"
          aid="instruction-type-select"
          @update:model-value="currentStep = 0"
        >
          <WsSelectOption
            v-for="(value, key) in stepsByTypeOptions"
            :key="key"
            :value="{ id: key, name: value }"
            :aid="`${key}-option`"
            class="text-md font-normal"
          >
            {{ value }}
            <template v-if="key === 'recommended' && id !== 'OTHER'" #append>
              <WsBadge
                label="Recommended"
                aid="recommended-badge"
                class="uppercase shrink-0"
              />
            </template>
          </WsSelectOption>
        </WsSelect>
      </div>
      <ul class="h-[calc(100vh-200px)] overflow-y-auto">
        <InstructionStep
          v-for="(step, index) in stepsToShow"
          :key="index"
          :step-number="index + 1"
          :text="step.text"
          :sub-steps="step.subSteps"
          :is-active="currentStep === index"
          :aid="step.aid"
          @click="changeStep(index)"
        >
          <WsButton
            v-if="step.copySignature"
            :label="copyButtonLabel"
            color="primary"
            :loading="isBtnLoading"
            size="sm"
            aid="copy"
            @click="copySignature('html')"
          />
          <WsButton
            v-if="step.copyToken"
            :label="copyButtonLabel"
            color="primary"
            :loading="isBtnLoading"
            size="sm"
            aid="copy"
            @click="copyOutlookDesktopAppToken"
          />
          <WsButton
            v-if="step.sendSignature"
            :label="sendSignatureButtonLabel"
            color="primary"
            :loading="isBtnLoading"
            size="sm"
            aid="copy"
            @click="sendSignature"
          />
          <WsButton
            v-if="step.copyHtml"
            :label="copyButtonLabel"
            color="primary"
            :loading="isBtnLoading"
            size="sm"
            aid="copy"
            @click="copySignature('text')"
          />
        </InstructionStep>
      </ul>
    </div>
    <div
      class="flex-1 flex justify-center items-center p-20 border border-gray-100 flex-col gap-3 w-full box-border me-10 mt-10"
    >
      <slot
        name="preview"
        :src="resolveImagePath(stepsToShow[currentStep].previewImage)"
      ></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import InstructionStep from './instruction-step.vue';
import {
  WsSelect,
  WsSelectOption,
  WsBadge,
  WsButton,
} from '@mfl/common-components';
import { useRoute } from 'vue-router';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';

const route = useRoute();

const props = defineProps<{
  id: string;
  title: string;
  steps: Array<{
    text: string;
    previewImage: string;
    subSteps?: string[];
    copySignature?: boolean;
    copyToken?: boolean;
    copyHtml?: boolean;
    sendSignature?: boolean;
    aid?: string;
  }>;
  stepsByTypeOptions?: { recommended: string; copy: string };
  stepsByType?: {
    recommended: Array<{
      text: string;
      previewImage: string;
      subSteps?: string[];
      copySignature?: boolean;
      copyToken?: boolean;
      copyHtml?: boolean;
      sendSignature?: boolean;
      aid?: string;
    }>;
    copy: Array<{
      text: string;
      previewImage: string;
      subSteps?: string[];
      copySignature?: boolean;
      copyToken?: boolean;
      copyHtml?: boolean;
      sendSignature?: boolean;
      aid?: string;
    }>;
  };
}>();

const currentStep = ref(0);
const isBtnLoading = ref(false);
const copyButtonLabel = ref('Copy');
const sendSignatureButtonLabel = ref('Send');
const selectedType = ref({
  id: 'recommended' as 'recommended' | 'copy',
  name: props.stepsByTypeOptions?.recommended || '',
});

const optionLabel = (option: { id: string; name: string }) => option.name;
const optionKey = (option: { id: string; name: string }) => option.id;

const stepsToShow = computed(() => {
  if (props.stepsByType) {
    return props.stepsByType[selectedType.value.id as 'recommended' | 'copy'];
  }
  return props.steps;
});

function changeStep(index: number) {
  currentStep.value = index;
}

function resolveImagePath(imagePath: string): string {
  return import.meta.resolve(import.meta.env.BASE_URL + imagePath);
}

async function copyToClipboard(
  content: string,
  type: 'text' | 'html' = 'text'
) {
  const mimeType = {
    html: 'text/html',
    text: 'text/plain',
  }[type];

  const blob = new Blob([content], { type: mimeType });
  const clipboardItem = new ClipboardItem({ [mimeType]: blob });

  await navigator.clipboard.write([clipboardItem]);
}

async function copyOutlookDesktopAppToken() {
  isBtnLoading.value = true;
  const originalLabel = copyButtonLabel.value;

  try {
    const response = await employeePortalGateway.getOutlookToken({});

    if (!response.token) {
      throw new Error('Did not get token in response.');
    }
    await copyToClipboard(response.token, 'text');
    copyButtonLabel.value = 'Copied!';
    setTimeout(() => {
      copyButtonLabel.value = originalLabel;
    }, 3000);
  } catch (error) {
    // TODO: show a toast
    console.log(error);
    throw error;
  } finally {
    isBtnLoading.value = false;
  }
}

async function sendSignature() {
  isBtnLoading.value = true;
  const originalLabel = sendSignatureButtonLabel.value;

  const signatureId = route.params.signatureId;
  if (!signatureId || typeof signatureId !== 'string') {
    throw new Error('Invalid signatureId');
  }
  try {
    await employeePortalGateway.sendSignature({ signatureId });
    sendSignatureButtonLabel.value = 'Sent!';
    setTimeout(() => {
      sendSignatureButtonLabel.value = originalLabel;
    }, 3000);
  } catch (error) {
    // TODO: show a toast
    console.log(error);
    throw error;
  } finally {
    isBtnLoading.value = false;
  }
}

async function copySignature(type: 'text' | 'html' = 'text'): Promise<void> {
  isBtnLoading.value = true;
  const originalLabel = copyButtonLabel.value;

  const signatureId = route.params.signatureId;
  if (!signatureId || typeof signatureId !== 'string') {
    throw new Error('Invalid signatureId');
  }

  try {
    const getSignatureHtmlFromCache = (id: string): string | null =>
      localStorage.getItem(`signatureHtml_${id}`);

    const setSignatureHtmlInCache = (id: string, html: string): void => {
      localStorage.setItem(`signatureHtml_${id}`, html);
    };

    let signatureHtml = getSignatureHtmlFromCache(signatureId);

    if (!signatureHtml) {
      const response = await employeePortalGateway.renderSignatureById({
        signatureId,
      });

      if (response?.html) {
        signatureHtml = response.html;
        setSignatureHtmlInCache(signatureId, signatureHtml);
      } else {
        throw new Error('Failed to render signature');
      }
    }

    if (signatureHtml) {
      await copyToClipboard(signatureHtml, type);
      copyButtonLabel.value = 'Copied!';
      setTimeout(() => {
        copyButtonLabel.value = originalLabel;
      }, 3000);
    } else {
      throw new Error('Signature HTML is empty');
    }
  } catch (error) {
    // TODO: show a toast
    console.log(error);
    throw error;
  } finally {
    isBtnLoading.value = false;
  }
}
</script>
