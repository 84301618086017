<template>
  <div class="flex flex-col">
    <div class="flex gap-4 px-8 pt-6 pb-6 text-xl font-bold text-gray-500">
      {{ strings.profileTitle }}
    </div>

    <ProfilePhoto
      v-if="photo && photo.fieldType === 'image' && photo.isEditable"
      :aid="`FIELD_${photo.property.toUpperCase()}`"
      :name="name"
      :photo="photo.value"
      class="mx-9 my-8"
      @done="
        photo.value = $event.imageUrl;
        preventPageUnload();
      "
    />

    <form
      class="w-full max-w-2xl px-8 py-6 pt-0 space-y-[14px]"
      @submit.prevent="saveChanges"
    >
      <div class="bg-white shadow rounded-lg p-6">
        <div class="text-md font-bold text-gray-400 pb-6">
          {{ strings.profileContactDetailsTitle }}
        </div>

        <template v-for="field in fields" :key="field.property">
          <WsInput
            v-if="field.fieldType === 'text'"
            v-model="field.value"
            :aid="`FIELD_${field.property.toUpperCase()}`"
            :readonly="!field.isEditable"
            :label="field.label"
            @update:model-value="preventPageUnload()"
          >
            <WsTooltip
              v-if="!field.isEditable"
              position="right"
              :aid="`FIELD_${field.property.toUpperCase()}_READONLY_TOOLTIP`"
            >
              {{ strings.signatureEditFieldReadonlyTooltip }}
            </WsTooltip>
          </WsInput>

          <WsSelect
            v-else-if="field.fieldType === 'dropdown'"
            v-model="field.value"
            :aid="`FIELD_${field.property.toUpperCase()}`"
            :readonly="!field.isEditable"
            :label="field.label"
            :option-label="(l) => l"
            :option-key="(k) => k"
            class="mb-5"
            advanced
            @update:model-value="preventPageUnload()"
          >
            <WsSelectTrigger>
              <template v-if="!field.isEditable" #append>
                <WsTooltip
                  position="right"
                  :aid="`FIELD_${field.property.toUpperCase()}_READONLY_TOOLTIP`"
                >
                  {{ strings.signatureEditFieldReadonlyTooltip }}
                </WsTooltip>
              </template>
            </WsSelectTrigger>

            <WsSelectPanel>
              <WsSelectOptions>
                <WsSelectOption
                  v-for="(item, index) in field.values"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </WsSelectOption>
              </WsSelectOptions>
            </WsSelectPanel>
          </WsSelect>
        </template>
      </div>
    </form>

    <div
      class="w-full sticky bottom-0 bg-white border-t border-gray-200 px-8 py-4"
    >
      <WsButton
        :label="buttonLabel"
        :disabled="!hasChanges"
        :loading="isSaving"
        aid="SAVE_CHANGES"
        @click="saveChanges"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, reactive, watch } from 'vue';
import strings from '../employee-portal-signatures.strings';
import { useRouter } from 'vue-router';

import {
  WsButton,
  WsInput,
  WsTooltip,
  WsSelect,
  WsSelectOption,
  WsSelectOptions,
  WsSelectPanel,
  WsSelectTrigger,
} from '@mfl/common-components';
import {
  navigateTo,
  preventPageUnload,
  releasePageUnload,
} from '@mfl/framework';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';
import { headerComponents, showLogo } from '@mfe/core-header';
import { currentUser } from '@mfl/employee-portal-shell';
import ProfilePhoto from '../components/profile-photo.vue';
import HeaderBackButton from '../components/header-back-button.vue';

const router = useRouter();

type EmployeeField = {
  property: string;
  value: string;
  label: string;
  fieldType: string;
  isEditable: boolean;
  locked?: boolean;
  values?: string[];
};

const originalFields = reactive<EmployeeField[]>([]);
const fields = reactive<EmployeeField[]>([]);
const isSaved = ref<boolean>(false);
const isSaving = ref<boolean>(false);

function getOriginalFieldValue(property: string): string {
  return (
    originalFields.find((field) => field.property === property)?.value ?? ''
  );
}

function getFieldValue(property: string): string {
  return fields.find((field) => field.property === property)?.value ?? '';
}

const name = computed<string>(() => {
  return getOriginalFieldValue('name');
});

const photo = computed(() => {
  return fields.find((field) => field.property === 'photo');
});

const hasChanges = computed<boolean>(() => {
  return fields.some(
    (field) => field.value !== getOriginalFieldValue(field.property)
  );
});

const buttonLabel = computed<string>(() => {
  if (isSaved.value) {
    return strings.profileContactBtnSaved;
  }

  return strings.profileContactBtnSaveChanges;
});

watch(hasChanges, (changed) => {
  if (changed) {
    isSaved.value = false;
  }
});

let unregisterHeaderBackButtonComponent: () => void;

async function saveChanges() {
  isSaving.value = true;

  const data = fields
    .filter(
      (field) =>
        field.isEditable &&
        field.value !== getOriginalFieldValue(field.property)
    )
    .reduce(
      (result, field) => {
        result[field.property] = field.value ?? '';
        return result;
      },
      {} as Record<string, string>
    );

  const { name, email, ...employeeData } = data;

  try {
    const response = await employeePortalGateway.updateEmployee({
      employee: {
        name,
        employeeData,
      },
    });

    if (response.success) {
      isSaved.value = true;

      originalFields.forEach((field: EmployeeField) => {
        field.value = getFieldValue(field.property);
      });

      if (name) {
        currentUser.setName(name);
      }
    } else {
      console.error('Failed to save changes');
    }
  } catch (error) {
    console.error(error);
  }
  isSaving.value = false;

  releasePageUnload();
}

function getSortPriority(field: EmployeeField): number {
  // 0 => the highest priority (goes first), larger => lower priority (goes later)
  if (field.property === 'photo') return 0; // photo first
  if (field.property.endsWith('_link')) return 2; // _link last
  return 1; // everything else in between
}

async function fetchFields() {
  const response = (await employeePortalGateway.getFields({})) as {
    fields: EmployeeField[];
  };

  const data = response.fields.sort(
    (a: EmployeeField, b: EmployeeField) =>
      getSortPriority(a) - getSortPriority(b)
  );

  originalFields.push(...structuredClone(data));
  fields.push(...structuredClone(data));
}

onMounted(async () => {
  showLogo(false);

  const signaturesHref = router.resolve({ name: 'signatures' }).href;

  unregisterHeaderBackButtonComponent = headerComponents.register({
    key: 'INSTRUCTIONS_HEADER_BACK_BUTTON',
    component: HeaderBackButton,
    props: {
      href: signaturesHref,
    },
    events: {
      click: () => void navigateTo(signaturesHref),
    },
  });

  await fetchFields();
});

onUnmounted(() => {
  showLogo(true);

  unregisterHeaderBackButtonComponent();

  releasePageUnload();
});
</script>
