<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { vLoadingL } from '@mfl/common-components';
import { headerComponents, showLogo } from '@mfe/core-header';
import { navigateTo } from '@mfl/framework';
import { useInstructionsNavigation } from './navigation/instructions-nav';

import HeaderBackButton from '../../components/header-back-button.vue';

const router = useRouter();
const route = useRoute();

// Take current sigId and allowedInstructions from the route and register allowed navigation items
const signatureId = route.params.signatureId;

const { navBarReady } = useInstructionsNavigation(signatureId as string);

let unregisterHeaderBackButtonComponent: () => void;

onMounted(() => {
  showLogo(false);

  const signaturesHref = router.resolve({ name: 'signatures' }).href;

  unregisterHeaderBackButtonComponent = headerComponents.register({
    key: 'INSTRUCTIONS_HEADER_BACK_BUTTON',
    component: HeaderBackButton,
    props: {
      href: signaturesHref,
    },
    events: {
      click: () => void navigateTo(signaturesHref),
    },
  });
});

onUnmounted(() => {
  showLogo(true);
  unregisterHeaderBackButtonComponent();
});
</script>

<template>
  <div v-loading-l="!navBarReady" class="h-screen">
    <div v-show="navBarReady">
      <RouterView v-slot="{ Component }">
        <div
          v-if="!Component"
          class="flex flex-col items-center h-full mt-24 text-center"
        >
          <img src="/preview.png" alt="preview" class="max-w-xl mb-5" />
          <div class="text-xl font-bold">
            Choose the email services where you want your signature installed
          </div>
        </div>
        <component :is="Component" v-else />
      </RouterView>
    </div>
  </div>
</template>
